// AccountDeletion.js
import axios from 'axios';
import { useState } from 'react';
import '../../App.css';
import './AccountDeletion.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AccountDeletion = () => {
    const [ email, setEmail ] = useState( '' );
    const [ confirm, setConfirm ] = useState( false );
    const [ message, setMessage ] = useState( '' );
    const [ error, setError ] = useState( '' );
    const [ loading, setLoading ] = useState( false );


    const handleSubmit = async ( e: any ) => {
        e.preventDefault();

        if ( !confirm ) {
            setError( 'Please confirm that you understand the consequences of deleting your account.' );
            return;
        }

        setLoading( true );

        try {
            await axios.post( process.env.REACT_APP_WEBSITE_ASTERIUS_API + '/account-deletion-request', { email } );
            
            toast.success( 'Your request has been submitted!', { autoClose: false } );
            setEmail( '' );
            setConfirm( false );
            setError( '' );
        } catch ( error ) {
            console.error( error );
            toast.error( 'There was an error processing your request.', { autoClose: 10000 } );
        } finally {
            setLoading( false );
        }
    };

    return (
        <div className="account-deletion-container"> 
            <MenuHeader />
            <ToastContainer />

            <div className="account-deletion-content-container">
                <h1 className="delete-main-header">Account Deletion Request</h1>
                <hr className="custom-hr" style={{ backgroundColor: '#302b63'}} />
                <p className="deletion-p">
                    <div className="delete-header1">We're sorry to see you go!</div>
                    Deleting your account is permanent and cannot be undone.
                    Please ensure you have backed up any important data before proceeding.
                    This is a request form and account deletion will be serviced as quickly as possible, but the deletion will not happen automatically or immediately.
                    We will reach out to you to verify this request really came from you.
                </p>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="email">
                        <div><strong>Enter your email address to request account deletion:</strong></div>
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        placeholder="Enter your email..."
                        onChange={( e ) => setEmail( e.target.value )}
                        required
                        className="email-delete-input"
                    />
                    <div className="confirmation">
                        <input
                            type="checkbox"
                            id="confirm"
                            name="confirm"
                            checked={confirm}
                            onChange={( e ) => setConfirm( e.target.checked )}
                            required
                            className="large-checkbox"
                        />
                        <label htmlFor="confirm">
                            I understand that deleting my account is permanent and cannot be undone.
                        </label>
                    </div>
                    <button type="submit" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit Request'}
                    </button>

                </form>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                <div className="additional-info">
                    <p>
                        For more information, please review our{' '}
                        <a href="https://www.termsfeed.com/live/79ef53ec-6bc3-4282-a81a-168fd35ad18c" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
};

const MenuHeader: React.FC = () => {
    return (
        <nav className="menu-header">
            <div className="logo">
                <img src="/step2-final-original-not-cut-off-rotated.png" alt="Asterius Logo" />
                <span>asterius&nbsp;</span>
            </div>
        </nav>
    );
};

const Footer: React.FC = () => {
    return (
        <footer className="footer footer-for-short-page">
            <p>&copy; 2024 xstatikos, LLC. All rights reserved.</p>
        </footer>
    );
};

export default AccountDeletion;
