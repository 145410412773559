import { faCheckCircle, faClipboardList, faFlag, faPlay } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import '../../Animations.css';
import '../../App.css';

import agreementPlanStyles from '../../css/AgreementPlan.module.css';
import planStyles from '../../css/Plan.module.css';
import pricingStyles from '../../css/Pricing.module.css';
import testimonialStyles from '../../css/Testimonial.module.css';
import useScrollAnimation from '../../hooks/useScrollAnimation';
import ProgressBar from '../../ProgressBar/ProgressBar';

/* 
TODO

The header for the stakes and benefits, let's do something with it. maybe have it type in. I did this in my app.  

---

add this cool scroll progress bar to the top so they know how far along they've gotten into the "story"
import { motion, useScroll } from "framer-motion"

export const CircleIndicator = () => {
  const { scrollYProgress } = useScroll()

  return (
    <motion.path
      d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
      style={{ pathLength: scrollYProgress }}
    />
  )
}
  https://www.framer.com/motion/use-scroll/

  this could also be cool: https://codesandbox.io/p/sandbox/framer-motion-scroll-velocity-r1dy4u?from-embed=
  */

const Home: React.FC = () => {

  const [ counter, setCounter ] = useState<number | null>( null );

  // Fetch the current counter from the backend
  useEffect( () => {
    const fetchCounter = async () => {
      try {
        const response = await axios.get( process.env.REACT_APP_WEBSITE_ASTERIUS_API + '/discounts/get-counter' );
        const { counter } = response.data;
        setCounter( counter );
      } catch ( error ) {
        console.error( 'Error fetching discount data:', error );
      }
    };

    fetchCounter();
  }, [] );

  // Handle discount claim (download button click)
  const handleDownloadClick = async () => {
    // Check if they've already downloaded (localStorage check)

    window.open( process.env.REACT_APP_DOWNLOAD_URL, '_blank' );

    const hasDownloaded = localStorage.getItem( 'hasDownloaded' );
    if ( !hasDownloaded ) {
      try {
        const response = await axios.post( process.env.REACT_APP_WEBSITE_ASTERIUS_API + '/discounts/download-app' );
        if ( response.data.remaining !== undefined ) {
          //setCounter( response.data.remaining );
        }

        // Set localStorage to prevent further decrements
        localStorage.setItem( 'hasDownloaded', 'true' );
      } catch ( error: unknown ) {
        // Type guard for AxiosError structure
        if ( axios.isAxiosError( error ) && error.response ) {
        } else {
        }
        console.error( 'Error claiming discount:', error );
      }

    }

  };

  return (
    <div className="App">
      <MenuHeader handleDownloadClick={handleDownloadClick} />
      <ProgressBar />
      <Header handleDownloadClick={handleDownloadClick} />

      {/* <StakesGreenBackground /> */}
      <StakesPurpleBackground />

      <BenefitsPurpleBackground />

      {/* I switched these two */}
      <Plan />
      <Guide />

      <ExplanatoryParagraph handleDownloadClick={handleDownloadClick} />

      <AgreementPlan />

      {/* <VideoSection /> */}

      <PriceSection
        counter={counter}
        handleDownloadClick={handleDownloadClick}
      />

      <Footer />
    </div>
  );
}

interface MenuHeaderProps {
  handleDownloadClick: () => void;
}

const MenuHeader: React.FC<MenuHeaderProps> = ( { handleDownloadClick } ) => {
  return (
    <nav className="menu-header">
      {/* <div className="logo" style={{ marginTop: '-10px' }}> */}
      <div className="logo">


        {/* <img src="/logo-final2-rotated.png" /> */}
        {/* <img src="/logo-final2-middle-long.png" /> */}
        {/* <img src="/logo-final2-middle-longer-both.png" /> */}

        {/* I like this one without any blur around it. It looks good clean cut here */}
        <img src="/step2-final-original-not-cut-off-rotated.png" />

        {/*         <img src="/crooked-for-site.png" style={{ width: '45px', marginRight: '0px', paddingBottom: '10px', marginBottom: '-28px', marginLeft: '-5px' }} />
 */}
        {/* <img src="/step2-less-fade.png" /> */}

        {/* <img src="/step2-less-less-blur.png" /> */}
        {/* <img src="/step2-cubism3.png" /> */}
        <span>asterius&nbsp;</span>
      </div>
      <div className="cta-button">
        <button className="sweet-button" onClick={handleDownloadClick}> <i>Download asterius</i></button>
      </div>
    </nav>
  );
}

/*
const Header: React.FC = () => {
  return (
    <header className="header">
      <h1>Get <span className="asterius-text-blue">abs</span>. Get <span className="asterius-text-blue">shredded</span>.</h1>

      <p>An <span className="asterius-text-blue">irresistible body</span> shouldn't just be for<br /> models and movie stars</p>

      <div className="cta-container">
        <div className="cta-subtext">

          <div>Most people secretly feel embarassed by<br />their body</div>

          <div className="asterius-text-blue" style={{ marginTop: '10px' }}>You deserve more.</div>

          <img src="/down-arrow.png" alt="arrow" className="arrow-img" style={{ width: '30px' }} />

        </div>
        <button className="sweet-button"> Get <strong><i>asterius</i></strong><strong></strong> now</button>
      </div>
    </header>
  );
}
  */

interface HeaderProps {
  handleDownloadClick: () => void;
}

const Header: React.FC<HeaderProps> = ( { handleDownloadClick } ) => {

  return (
    <header className="header">

      <br />

      <h1><span className="ion-title-whiter">Get</span> <span className="asterius-text-blue ion-title">abs. </span><br /> <span className="ion-title-whiter">Get</span> <span className="asterius-text-blue ion-title">shredded. </span> </h1>

      <hr className="custom-hr" />
      <br />

      <div className="ion-title-small-text-white">

        {/* <p>Most people are secretly embarassed by<br />their body</p> */}
        <p style={{ marginTop: '15px' }}>Most people secretly feel embarassed about<br />their body</p>

        <p>After using asterius, you'll <br />
          <div style={{ marginTop: '15px' }}><span className="ion-title-small-text-blue">look forward to <br />taking your shirt off </span></div> </p>

      </div>

      <img src="/down-arrow.png" alt="arrow" className="arrow-img hidden slide-in-down-animation" style={{ width: '30px' }} />

      <button className="sweet-button larger hidden scale-in-animation" onClick={handleDownloadClick}> Get <strong><i>asterius</i></strong><strong></strong> now</button>

      <img className="bottom-phone-image hidden slide-in-up-animation" src="/app-phone-mockup/phone-blue.png" />


    </header>
  );
}

interface SectionProps {
  children: React.ReactNode;
}

/* todo rename this */
const AnimateInBulletPointSectionStakeForPurpleBackground: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="stake-section">
      <div className="expanding-text">{children}</div>
    </div>
  );
};

const AnimateInBulletPointSectionBenefitForPurpleBackground: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="benefit-section">
      <div className="expanding-text">{children}</div>
    </div>
  );
};

const AnimateInBulletPointSectionStakeForGreenBackground: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="stake-section">
      <div className="expanding-text expanding-text-purple">{children}</div>
    </div>
  );
};

const AnimateInBulletPointSectionBenefitForGreenBackground: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="benefit-section">
      <div className="expanding-text expanding-text-purple">{children}</div>
    </div>
  );
};

const StakesGreenBackground: React.FC = () => {

  useScrollAnimation( '.stakes-container', '.stakes-header', 'dissolve-in-animation' );
  useScrollAnimation( '.stake-section', '.expanding-text', 'slide-in-down-animation' );

  return (

    <>

      <section className="stakes-section">

        <div style={{ fontWeight: 'bold', color: '#302b63' }}>

          <div className="container-white stakes-container">
            <h2 className="asterius-text-blue ion-title hidden container-header stakes-header ion-title-purple" style={{ marginBottom: '0', wordSpacing: '2px' }}>Sticking to a diet or workout<br />feels <i>impossible</i></h2>

            <AnimateInBulletPointSectionStakeForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                Are you tired of people <span className="asterius-text-purple-override-graident-text">pretending not to judge you</span> when you take your shirt off?
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>
            </AnimateInBulletPointSectionStakeForGreenBackground>

            <AnimateInBulletPointSectionStakeForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                Do you ever look down and wonder<br /> <span className="asterius-text-purple-override-graident-text">what the heck happened?</span>
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>
            </AnimateInBulletPointSectionStakeForGreenBackground>

            <AnimateInBulletPointSectionStakeForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                And sick of everyone losing fat while you're left <span className="asterius-text-purple-override-graident-text">feeling jiggly and alone?</span>
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionStakeForGreenBackground>
          </div>



        </div>

      </section>
    </>

  );
}

const StakesPurpleBackground: React.FC = () => {

  useScrollAnimation( '.stakes-container', '.stakes-header', 'dissolve-in-animation' );
  useScrollAnimation( '.stake-section', '.expanding-text', 'slide-in-down-animation' );

  return (

    <>

      <section className="stakes-section">

        <div style={{ fontWeight: 'bold', color: '#302b63' }}>

          <div className="container stakes-container">
            <h2 className="asterius-text-blue ion-title hidden container-header stakes-header" style={{ marginBottom: '0' }}>Sticking to a diet or workout<br />feels <i>impossible</i></h2>

            <AnimateInBulletPointSectionStakeForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                Are you tired of people <span className="asterius-text-blue-override-graident-text">pretending not to judge you</span> when you take your shirt off?
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>
            </AnimateInBulletPointSectionStakeForPurpleBackground>

            <AnimateInBulletPointSectionStakeForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                Do you ever look down and wonder <span className="asterius-text-blue-override-graident-text">what the heck happened?</span>
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>
            </AnimateInBulletPointSectionStakeForPurpleBackground>

            <AnimateInBulletPointSectionStakeForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                And sick of everyone losing fat while you're left <span className="asterius-text-blue-override-graident-text">feeling jiggly and alone?</span>
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionStakeForPurpleBackground>
          </div>



        </div>

      </section>
    </>

  );
}

const BenefitsGreenBackground: React.FC = () => {

  useScrollAnimation( '.benefit-section', '.expanding-text', 'grow-text-animation' );

  // for title of section
  useScrollAnimation( '.benefits-container', '.benefits-header', 'dissolve-in-animation' );
  useScrollAnimation( '.benefits-container', '.crazy-but', 'dissolve-in-animation' );

  return (

    <>

      <section className="stakes-section" >

        <div style={{ fontWeight: 'bold', color: '#302b63' }}>

          <div className="container-white benefits-container">
            <h2 className="asterius-text-blue ion-title container-header hidden benefits-header ion-title-purple" style={{ wordSpacing: '2px' }}>

              {/* craves
              wishes they had
              wants
              desires
              */}
              When you use asterius,<br />you get what<br />everyone <i>wants</i>
            </h2>
            <h3 style={{ fontSize: '23px', letterSpacing: '-1.5px' }} className="hidden crazy-but ion-title ion-title-purple"> It might sound crazy, but...</h3>

            <AnimateInBulletPointSectionBenefitForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                You deserve to <span className="asterius-text-purple-override-graident-text">make others jealous</span> when you take your shirt off
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForGreenBackground>
            <AnimateInBulletPointSectionBenefitForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                You deserve to <span className="asterius-text-purple-override-graident-text">catch attractive people</span> trying not to get caught <span className="asterius-text-purple-override-graident-text">staring at your abs</span>
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForGreenBackground>
            <AnimateInBulletPointSectionBenefitForGreenBackground>
              <h2 className="ion-title-small-text-white ion-title section-text ion-title-purple" style={{ letterSpacing: '-1.5px' }}>
                {/* You deserve to finally have a body that you're <i>proud</i> to show off */}
                You deserve to finally have a body that you <span className="asterius-text-purple-override-graident-text"><i>want</i> to show off</span>
                <hr className="custom-hr-purple" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForGreenBackground>
          </div>

        </div>

      </section>
    </>

  );
}

const BenefitsPurpleBackground: React.FC = () => {

  // useScrollAnimation( '.benefit-section', '.expanding-text', 'fade-in-animation' );
  // useScrollAnimation( '.benefit-section', '.expanding-text', 'flip-in-x-animation' );
  // useScrollAnimation( '.benefit-section', '.expanding-text', 'bounce-in-animation' );
  // useScrollAnimation( '.benefit-section', '.expanding-text', 'zoom-in-animation' );
  useScrollAnimation( '.benefit-section', '.expanding-text', 'grow-text-animation' );
  // useScrollAnimation( '.benefit-section', '.expanding-text', 'scale-in-animation' );


  // for title of section
  useScrollAnimation( '.benefits-container', '.benefits-header', 'dissolve-in-animation' );
  useScrollAnimation( '.benefits-container', '.crazy-but', 'dissolve-in-animation' );

  return (

    <>

      <section className="stakes-section" >

        <div style={{ fontWeight: 'bold', color: '#302b63' }}>

          <div className="container benefits-container">
            <h2 className="asterius-text-blue ion-title container-header hidden benefits-header">

              {/* craves
              wishes they had
              wants
              desires
              */}
              When you use asterius,<br />you get what<br />everyone <i>wants</i>
            </h2>
            <h3 style={{ color: '#fff', fontSize: '23px' }} className="hidden crazy-but"> It might sound crazy, but...</h3>

            <AnimateInBulletPointSectionBenefitForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                You deserve to <span className="asterius-text-blue-override-graident-text">make others jealous</span> when you take your shirt off
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForPurpleBackground>
            <AnimateInBulletPointSectionBenefitForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                You deserve to <span className="asterius-text-blue-override-graident-text">catch attractive people</span> trying not to get caught <span className="asterius-text-blue-override-graident-text">staring at your abs</span>
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForPurpleBackground>
            <AnimateInBulletPointSectionBenefitForPurpleBackground>
              <h2 className="ion-title-small-text-white ion-title section-text">
                {/* You deserve to finally have a body that you're <i>proud</i> to show off */}
                You deserve to finally have a body that you <span className="asterius-text-blue-override-graident-text"><i>want</i> to show off</span>
                <hr className="custom-hr" style={{ width: '75px' }} />
              </h2>

            </AnimateInBulletPointSectionBenefitForPurpleBackground>
          </div>

        </div>

      </section>
    </>

  );
}

const BenefitsOLD: React.FC = () => {
  return (
    <section className="benefits-section" style={{ fontWeight: 'bold', color: '#302b63' }}>

      <h2>When you use asterius,<br />you get what<br />everyone <i>craves</i></h2>
      <ul>
        <li>You deserve to make others jealous when you take your shirt off</li>
        <li>You'll catch attractive people trying not to get caught staring at your abs</li>
        <li>You will finally have a body that you're <i>proud</i> to show off</li>
      </ul>
    </section>
  );
}

const CrookedArrowRotatedIcon = () => {
  return (
    <div className="icon-wrapper">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: 'rotate(25deg)' }} /* update this to rotate */
      >
        <path
          d="M8 4H20V16"
          stroke="#00ffff"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 20L20 4"
          /* stroke="#00C8F8" */
          stroke="#00ffff"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity="0.6"  // Adjust the transparency here for the stem
        />
      </svg>
    </div>
  );
};

const AnimateInGuideSectionP: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="guide-paragraph-container">
      <p className="ion-title-small-text-white hidden guide-paragraph">{children}</p>
    </div>
  );
};

const AnimateInGuideSectionLi: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="guide-li-container">
      <li className="ion-title-small-text-white hidden guide-paragraph guide-li">{children}</li>
    </div>
  );
};

const Guide: React.FC = () => {
  useScrollAnimation( '.benefit-section', '.expanding-text', 'grow-text-animation' );
  // for title of section
  useScrollAnimation( '.guide-section', '.guide-header', 'dissolve-in-animation' );

  useScrollAnimation( '.guide-section', '.guide-paragraph', 'slide-in-down-animation' );
  useScrollAnimation( '.guide-paragraph-container', '.guide-paragraph', 'slide-in-down-animation' );
  useScrollAnimation( '.guide-li-container', '.guide-li', 'slide-in-down-animation' );

  return (
    <section className="container guide-section">
      <h2 className="asterius-text-blue ion-title container-header hidden guide-header" style={{ marginBottom: '0' }}>asterius is your guide</h2>

      <AnimateInGuideSectionP>Most workout programs were built by people who have been athletic and fit their whole life.</AnimateInGuideSectionP>
      <AnimateInGuideSectionP><span>You need someone who knows how to go from being <span className="asterius-text-blue-override-graident-text">jiggly</span> to ridiculously <span className="asterius-text-blue-override-graident-text">shredded</span>, not insanely fit to more fit.</span></AnimateInGuideSectionP>
      <AnimateInGuideSectionP>
        <span>
          Unlike them, we started out jiggly and insecure
          <hr className="custom-hr" style={{ width: '150px', marginBottom: '20px' }} />
        </span>
      </AnimateInGuideSectionP>


      {/* <h2 className="ion-title-small-text-white ion-title section-text">
        You deserve to <span className="asterius-text-blue-override-graident-text">make others jealous</span> when you take your shirt off
        <hr className="custom-hr" style={{ width: '75px' }} />
      </h2> */}

      <ul>
        <AnimateInGuideSectionLi><CrookedArrowRotatedIcon /><span>We've felt the<span className="asterius-text-blue-override-graident-text">dispair</span> and <span className="asterius-text-blue-override-graident-text">hopelessness</span>, thinking "I'll never really look the way I want".</span></AnimateInGuideSectionLi>
        <AnimateInGuideSectionLi><CrookedArrowRotatedIcon /><span>We've tried every<span className="asterius-text-blue-override-graident-text">failed diet</span></span></AnimateInGuideSectionLi>
        <AnimateInGuideSectionLi><CrookedArrowRotatedIcon /><span>We've jumped from one<span className="asterius-text-blue-override-graident-text">bad workout</span> program to the next</span></AnimateInGuideSectionLi>
        <AnimateInGuideSectionLi><CrookedArrowRotatedIcon /><span>Until we finally found the answers. The secret ingredients. The perfect program:<span className="asterius-text-blue-override-graident-text special">asterius.</span></span></AnimateInGuideSectionLi>

        <AnimateInGuideSectionLi>
          <TestimonialSection />
        </AnimateInGuideSectionLi>

      </ul>

      {/* <p className="ion-title-small-text-white guide-paragraph"><CrookedArrowRotatedIcon />We've felt the dispair and hopelessness, thinking "I'll never really look the way I want".</p>
      <p className="ion-title-small-text-white guide-paragraph"><CrookedArrowRotatedIcon />We've tried every failed diet</p>
      <p className="ion-title-small-text-white guide-paragraph"><CrookedArrowRotatedIcon />We've jumped from one bad workout program to the next</p>
      <p className="ion-title-small-text-white guide-paragraph"><CrookedArrowRotatedIcon />Until we finally found the answers. The secret ingredients. The perfect program. <span className="asterius-text-blue-override-graident-text">asterius.</span></p> */}



    </section>
  );
}

/* const TestimonialSectionWithQuote: React.FC = () => {
  useScrollAnimation( '.testimonial-main-container', '.testy', 'slide-in-up-animation' );
  const testimonials = [
    {
      beforeImage: './testimonials/j-before.jpeg',
      afterImage: './testimonials/j-after.jpg',
      text: 'I didn\'t think it would be possible, but asterius actually gave me abs and got me shredded!',
      name: 'Jonathan Irvin',
    },
  ];

  return (
    <div className="testimonial-main-container">
      <div className={`${ testimonialStyles.testimonialContainer } testy hidden`}>
        {testimonials.map( ( testimonial, index ) => (
          <div key={index} className={testimonialStyles.testimonialItem}>
            <div className={testimonialStyles.testimonialImages}>
              <img src={testimonial.beforeImage} alt="Before" className={testimonialStyles.testimonialImage} />
              <img src={testimonial.afterImage} alt="After" className={testimonialStyles.testimonialImage} />
            </div>
            <p className={`${ testimonialStyles.testimonialText } ion-title-small-text-white`}>{testimonial.text}</p>
            <p className={`${ testimonialStyles.testimonialName } ion-title-small-text-white`}>- {testimonial.name}</p>
          </div>
        ) )}
      </div>
    </div>
  );
}; */

const TestimonialSection: React.FC = () => {
  // enable this to get this specific components animation back (also add hidden class below - see below comment)
  // useScrollAnimation( '.testimonial-main-container', '.testy', 'slide-in-up-animation' );



  /* const testimonials = [
    {
      beforeImage: './testimonials/j-before.jpeg',
      afterImage: './testimonials/j-after.jpg',
      text: 'I didn\'t think it would be possible, but asterius actually gave me abs and got me shredded!',
      name: 'Jonathan Irvin',
    },
  ]; */

  const allImageSrcs = [
    { src: './testimonials/j-before.jpeg' },
    { src: './testimonials/j-after-s-and-c.jpg' },
    { src: './testimonials/matthew-before.jpg' },
    { src: './testimonials/matthew-after-cropped.jpg' },
  ];

  const testimonials = [

    {
      beforeImage: './testimonials/j-before.jpeg',
      afterImage: './testimonials/j-after-s-and-c.jpg',
      beforeLabel: 'Before',
      afterLabel: 'After',
      text: '',
      /* text: 'I didn\'t think it would be possible, but asterius actually gave me abs and got me shreddeda!', */
      name: '',
    },
    {
      beforeImage: './testimonials/matthew-before.jpg',
      afterImage: './testimonials/matthew-after-cropped.jpg',
      text: '',
      beforeLabel: 'Day 1',
      afterLabel: 'Day 30',
      name: '',
    },

  ];

  const [ currentIndex, setCurrentIndex ] = useState( 0 );

  const [ isOpen, setIsOpen ] = useState( false );

  const [ currentSlide, setCurrentSlide ] = useState( 0 );

  const handleNext = () => {
    setCurrentIndex( ( prevIndex ) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex( ( prevIndex ) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="testimonial-main-container">

      <Lightbox
        open={isOpen}
        close={() => setIsOpen( false )}
        slides={allImageSrcs}
        index={currentSlide}  // This sets the initial slide
      />

      <div className={`${ testimonialStyles.testimonialContainer } testy`}>
        <div className={testimonialStyles.testimonialItem}>
          <div className={testimonialStyles.testimonialImages}>
            <div className={testimonialStyles.testimonialImageContainer}>

              <img
                src={testimonials[ currentIndex ].beforeImage}
                alt="Before"
                className={testimonialStyles.testimonialImage}
                onClick={() => {
                  setCurrentSlide( currentIndex * 2 ); // Multiply by 2 to get the correct slide index
                  setIsOpen( true );
                }}
              />

              <p
                className={`${ testimonialStyles.imageLabel } ion-title-small-text-white`}
              >
                <span className="asterius-text-blue-override-graident-text special">
                  {testimonials[ currentIndex ].beforeLabel}
                </span>
              </p>
            </div>
            <div className={testimonialStyles.testimonialImageContainer}>

              <img
                src={testimonials[ currentIndex ].afterImage}
                alt="After"
                className={testimonialStyles.testimonialImage}
                onClick={() => {
                  setCurrentSlide( currentIndex * 2 + 1 ); // Multiply by 2 and add 1 for the after image
                  setIsOpen( true );
                }}
              />



              <p
                className={`${ testimonialStyles.imageLabel } ion-title-small-text-white`}
              >
                <span className="asterius-text-blue-override-graident-text special">
                  {testimonials[ currentIndex ].afterLabel}
                </span>
              </p>
            </div>
          </div>
          <p className={testimonialStyles.testimonialText}>
            {testimonials[ currentIndex ].text}
          </p>
          <p className={testimonialStyles.testimonialName}>
            {testimonials[ currentIndex ].name}
          </p>
        </div>
      </div>

      <div className={testimonialStyles.sliderControls}>
        <button onClick={handlePrev} className={`${ testimonialStyles.prevButton }`}>
          <img src="/down-arrow.png" alt="Left Arrow" className={testimonialStyles.leftArrow} />
        </button>
        <button onClick={handleNext} className={`${ testimonialStyles.nextButton }`}>
          {/* <span>&#8250;</span> */}
          <img src="/down-arrow.png" alt="Right Arrow" className={testimonialStyles.rightArrow} />
        </button>
      </div>
    </div>
  );
}

const AnimateInPlanSectionPlanItem: React.FC<SectionProps> = ( { children } ) => {
  return (
    <div className="plan-item-container">
      <div className="plan-item-child hidden">{children}</div>
    </div>
  );
};

const Plan: React.FC = () => {
  useScrollAnimation( '.plan-container', '.plan-header', 'dissolve-in-animation' );

  useScrollAnimation( '.plan-item-container', '.plan-item-child', 'slide-in-down-animation' );

  const plans = [
    {
      icon: faFlag,
      header: 'Step 1: Download asterius',
      subtext: 'asterius will be your guide to getting shredded',
    },
    {
      icon: faClipboardList,
      header: 'Step 2: Complete the daily checklist',
      subtext: 'Learn the secrets through a daily checklist of tasks that evolves as you progress in the app',
    },
    {
      icon: faPlay,
      header: 'Step 3: Get shredded. Get abs.',
      subtext: 'Live the life you\'ve always dreamed of',
    },
  ];

  return (

    <div className={`${ planStyles.planContainer } plan-container`}>
      <h2 className="asterius-text-blue ion-title container-header hidden plan-header">Your Plan</h2>
      {plans.map( ( plan, index ) => (
        <AnimateInPlanSectionPlanItem>
          <div key={index} className={`${ planStyles.planItem }`}>
            <FontAwesomeIcon icon={plan.icon} className={planStyles.planIcon} />
            <h2 className={planStyles.planHeaderH2}><span className={`${ planStyles.planHeader } asterius-text-blue-override-graident-text`}>{plan.header}</span></h2>
            <p className={planStyles.planSubtext}>{plan.subtext}</p>
          </div>
        </AnimateInPlanSectionPlanItem>

      ) )}
    </div>


  );
};

interface ExplanatoryParagraphBeforeChopProps {
  handleDownloadClick: () => void;
}

const ExplanatoryParagraph_BeforeChop: React.FC<ExplanatoryParagraphBeforeChopProps> = ( { handleDownloadClick } ) => {
  const [ isExpanded, setIsExpanded ] = useState( false );

  useScrollAnimation( '.explanatory-paragraph-container', '.explanatory-paragraph-header', 'dissolve-in-animation' );
  useScrollAnimation( '.explanatory-paragraph-container', '.explanatory-p', 'dissolve-in-animation' );

  const handleToggle = () => {
    setIsExpanded( !isExpanded );
  };
  return (
    <section className="explanatory-section">
      <div className="container explanatory-paragraph-container">
        <h2 className="asterius-text-blue ion-title hidden container-header explanatory-paragraph-header" style={{ marginBottom: '0' }}>As an asterius member</h2>
        <p className="explanatory-p hidden">

          You're are the kind of person who wants a life and a body that most only dream of.
          Someone who knows things you're not supposed to know.

          <br /><br />

          In order to get that, you need the right program to guide you.
          <br /><br />

          The problem is, you tried the diets that people worship like a religion.
          You went to the gym for your New Years resolution (or for a lot longer).
          It was all overly complicated, you felt judged, and none of it even worked.
          And if it was working, you definitely never got shredded.

          <br /><br />

          {!isExpanded && (
            <>
              <div className="cta-button">
                <button className="sweet-button" onClick={handleToggle}> Keep Reading...</button>
              </div>
              {/* <a href="#!" onClick={handleToggle}>
                Keep Reading...
              </a> */}
            </>
          )}
          {isExpanded && (

            <div id="readMore">
              <br /><br />
              This has made you feel hopeless and you've even given up on your dreams of ever seeing your abs!
              <br /><br />

              We believe you can be shredded - beyond belief. And have abs - ones you can see!<br /><br />

              Why should models and movie stars be the only ones with bodies that make everyone else jealous and full of lust?
              It's not right for them to keep the secret ingridents to themselves.
              <br /><br />

              But we understand how hard it is to figure out what those secret ingridents are.
              And how to mix all of those ingridents in the right way and at the right time in order to make the most mouthwatering body imaginable.
              <br /><br />

              That's why the asterius app was built.
              Through one secret ingrident at a time, we take the everyday jiggly bodies and the dad bods and leave them absolutely shredded.

              <br /><br />

              Here's how it works: download the asterius app, learn the secrets each day as you complete your daily checklist, get shredded.
              <br /><br />

              So is this the moment you become who you were always meant to be?
              Or will you stay trapped forever in a life and body you're embarrassed of?
              <br /><br />

              The truth is always simple.<br /><br />

              <div className="cta-button">
                <button className="sweet-button" onClick={handleDownloadClick}> Download <strong><i>asterius</i></strong> now</button>
              </div>
            </div>
          )}

        </p>
      </div>

    </section>
  );
}

interface ExplanatoryParagraphProps {
  handleDownloadClick: () => void;
}

const ExplanatoryParagraph: React.FC<ExplanatoryParagraphProps> = ( { handleDownloadClick } ) => {

  const AnimateInExplanatoryLine: React.FC<SectionProps> = ( { children } ) => {
    return (
      <div className="explanatory-line-container">
        <div className="hidden explanatory-line">{children}</div>
      </div>
    );
  };
  useScrollAnimation( '.explanatory-line-container', '.explanatory-line', 'slide-in-down-animation' );

  const [ isExpanded, setIsExpanded ] = useState( false );

  useScrollAnimation( '.explanatory-paragraph-container', '.explanatory-paragraph-header', 'dissolve-in-animation' );
  useScrollAnimation( '.explanatory-paragraph-container', '.explanatory-p', 'dissolve-in-animation' );

  const handleToggle = () => {
    setIsExpanded( !isExpanded );
  };
  return (
    <section className="explanatory-section">
      <div className="container explanatory-paragraph-container">
        <h2 className="asterius-text-blue ion-title hidden container-header explanatory-paragraph-header" style={{ marginBottom: '0' }}>As an asterius member</h2>
        <p className="explanatory-p hidden">

          You're the kind of person who wants a life and a body that <span className="asterius-text-blue-override-graident-text">most only dream of</span>.
          Someone who knows things you're not supposed to know. Someone who can do things other can't.

          <br /><br />

          In order to get that, you need <span className="asterius-text-blue-override-graident-text">the right program</span> to guide you.
          <br /><br />

          <span className="asterius-text-blue-override-graident-text">The problem is</span>, you tried the diets that people worship like a religion.
          You went to the gym for your New Years resolution (or for a lot longer).<br /><br />
          And it was all overly complicated, you felt judged, and <span className="asterius-text-blue-override-graident-text">none of it even worked</span>.
          And if it was working, you definitely never got shredded.

          <br /><br />

          {!isExpanded && (
            <>
              <div className="cta-button">
                <button className="sweet-button" onClick={handleToggle}> Keep Reading...</button>
              </div>
              {/* <a href="#!" onClick={handleToggle}>
                Keep Reading...
              </a> */}
            </>
          )}
        </p>

        {isExpanded && (

          <div className="explanatory-expanded-text">
            <hr className="custom-hr" style={{ width: '75px' }} />
            <div id="readMore">
              <br /><br />

              Which made you feel hopeless and you've even <span className="asterius-text-blue-override-graident-text">given up on your dreams</span> of ever seeing your abs!

              <br /><br />

              <AnimateInExplanatoryLine>
                We know <span className="asterius-text-blue-override-graident-text">you can be shredded</span>, beyond belief!<br /><br />
              </AnimateInExplanatoryLine>

              <AnimateInExplanatoryLine>
                Why should models and movie stars be the only ones with bodies that make everyone else jealous and full of lust?
                It's not right for them to keep the <span className="asterius-text-blue-override-graident-text">secrets to themselves</span>.
              </AnimateInExplanatoryLine>

              <br /><br />

              <AnimateInExplanatoryLine>
                But we understand how hard it is to figure out what those secrets are.
                And how to mix all of those secret ingridents in the right way and at the right time in order to make the most  <span className="asterius-text-blue-override-graident-text">mouthwatering body</span> imaginable.
              </AnimateInExplanatoryLine>
              <br /><br />

              <AnimateInExplanatoryLine>
                That's why the asterius app was built.
                Through one secret ingrident at a time, we take the  <span className="asterius-text-blue-override-graident-text">everyday jiggly bodies</span> and the  <span className="asterius-text-blue-override-graident-text">dad bods</span> and leave them  <span className="asterius-text-blue-override-graident-text">absolutely shredded</span>.
              </AnimateInExplanatoryLine>

              <br /><br />

              <AnimateInExplanatoryLine>
                Here's how it works:  <span className="asterius-text-blue-override-graident-text">download the asterius app</span>, <span className="asterius-text-blue-override-graident-text">learn the secrets</span> each day as you complete your daily checklist, <span className="asterius-text-blue-override-graident-text">get shredded</span>.
              </AnimateInExplanatoryLine>
              <br /><br />

              <AnimateInExplanatoryLine>
                So is this the moment you become who <span className="asterius-text-blue-override-graident-text">you were always meant to be</span>?
                Or will you stay trapped forever in a life and body you're embarrassed of?
              </AnimateInExplanatoryLine>

              <br /><br />

              <AnimateInExplanatoryLine>
                You're<span className="asterius-text-blue-override-graident-text"> free to choose.</span> <br /> <br />

                <span className="asterius-text-blue-override-graident-text">The truth</span> is always simple.<br /><br />
              </AnimateInExplanatoryLine>

              <AnimateInExplanatoryLine>
                <div className="cta-button">
                  <button className="sweet-button" onClick={handleDownloadClick}> Download <strong><i>asterius</i></strong> now</button>
                </div>
              </AnimateInExplanatoryLine>

            </div>
          </div>

        )}


      </div>

    </section>
  );
}

const AgreementPlan: React.FC = () => {
  const [ expandedIndex, setExpandedIndex ] = useState<number | null>( null );
  const contentRefs = useRef<( HTMLDivElement | null )[]>( [] );

  const toggleAccordion = ( index: number, event: any ) => {
    event.preventDefault();
    setExpandedIndex( expandedIndex === index ? null : index );
  };

  useEffect( () => {
    contentRefs.current.forEach( ( content, index ) => {
      if ( content ) {
        content.style.height = expandedIndex === index ? `${ content.scrollHeight }px` : '0px';
      }
    } );
  }, [ expandedIndex ] );

  useScrollAnimation( '.agreement-plan-container', '.agreement-plan-header', 'dissolve-in-animation' );
  useScrollAnimation( '.agreement-plan-container', '.accordion-container', 'dissolve-in-animation' );

  const faqs = [
    {
      question: 'It\'s too hard?',
      answer: 'You get to <span class="asterius-text-blue-override-graident-text">pick the option</span> to ramp up the difficulty over time or go hard right from the start',
    },
    {
      question: 'It costs too much?',
      answer: 'It\'s perfect timing, because we have a <span class="asterius-text-blue-override-graident-text">limited time offer</span> going on. You\'ll pay what it costs to eat one meal out a month, and right now you\'ll lock in that price <span class="asterius-text-blue-override-graident-text">forever</span>',
    },
    {
      question: 'I wanna quit?',
      answer: '<span class="asterius-text-blue-override-graident-text">Cancel at any time</span>, quickly and easily in your acccount',
    },
    {
      question: 'I want to think about it first?',
      answer: 'Download the app and try it out while you think! You have a <span class="asterius-text-blue-override-graident-text">3 day free trial</span> to try everything while you decide',
    },
    {
      question: 'I don\'t want to pay before I try it?',
      answer: 'Good, we don\'t want you to! <span class="asterius-text-blue-override-graident-text">No credit card is required</span> to start the free trial',
    },
    {
      question: 'I don\'t have enough time?',
      answer: 'The <span class="asterius-text-blue-override-graident-text">daily checklists</span> structure it so you always have enough time to do everything.',
    },
    {
      question: 'I don\'t think I can do it?',
      answer: 'The daily checklists make it so you <span class="asterius-text-blue-override-graident-text">never have more</span> than you can handle',
    },
    /* 
    re-enable when I add points and badges
    {
      question: 'I\'m afraid I won\'t be able to stick with it',
      answer: 'we make it easy to stick with it with point and badge incentives that make it feel more like playing a game than sticking to a routine',
    }, */
    {
      question: 'Can I just stay in bed instead?',
      answer: 'Sure, if you\'re okay with looking back when you\'re old and realizing you <span class="asterius-text-blue-override-graident-text">never got what you wanted</span> in life',
    },
    {
      question: 'It just doesn\'t work for me?',
      answer: ' if you follow the instructions laid out in the app, <span class="asterius-text-blue-override-graident-text">it will work for you</span>. It\'ll work for every shape, body type, age, gender. The only way it won\'t work is if you don\'t do it.',
    },
    /* 
    re-enable when I add points and badges
    {
      question: 'It\'s boring like all the others I\'ve tried?',
      answer: 'This will be more like a game than anything else. You\'ll be rewarded with points and badges and you\'ll even compete on the leaderboard for most points. Youre going to use this app every day for the rest of your life, so we wanted it to be invigorating, fun, and vibrant.',
    }, */
    {
      question: 'It\'s not easy?',
      answer: ' It\'s not going to be easy. You\'re going to have to work for it. But it will be <span class="asterius-text-blue-override-graident-text">straightforward, simple even</span>. We won\'t overwhelm you with a full course or 30 day routine.The tasks you get will be for <span class="asterius-text-blue-override-graident-text">today only</span>. You\'ll be able to focus only on what to do today instead of getting overwhelmed and quit because day 13 looks too hard. The app is <span class="asterius-text-blue-override-graident-text">simple to use</span> and you\'ll know exactly what to do at every step. So will it be easy? No. Will it be <span class="asterius-text-blue-override-graident-text">simple</span>? <span class="asterius-text-blue-override-graident-text">Yes!</span>',
    },
    {
      question: 'The progress takes forever to see?',
      answer: 'In 2 weeks <span class="asterius-text-blue-override-graident-text">you\'ll see progress</span>, 4 weeks <span class="asterius-text-blue-override-graident-text">you\'ll see more</span>, 6 weeks <span class="asterius-text-blue-override-graident-text">even more</span>, 3 months <span class="asterius-text-blue-override-graident-text">an insane amount</span> of progress. If you\'re consistent you will see progress and <span class="asterius-text-blue-override-graident-text">faster than you\'d think</span>. It\'s hard to see progress with your body when you see it every day. That\'s what the before and after pictures are for. Every time you take an after picture <span class="asterius-text-blue-override-graident-text">you\'ll be shocked</span> at what you looked like in the picture before. ',
    },
    {
      question: 'I\'ve tried other workout programs and they didn\'t work?',
/*       answer: 'Unlike other workout apps, this exact program is proven to take you from jiggly to shredded. It isn\'t just a workout app, it isn\'t just an app for eating. It\'s a full blown life companion app. You wake up and get your daily dose of mindset. Youll then do a workout that always feels new, fun, and exciting. You won\'t count calories, but rather follow a few different methodologies that make it so much easier to loose weight and keep it off forever. But more than all that you also get tasks to improve your lifestyle and the day to day living that\'s causing horrible damage to your mind and body. It\'s an app you\'ll be drawn towards and will benefit you every day for the rest of your life. Most apps will give you a workout routine or give you something for your diet, but usually never both, and even if they do give you both in 1 app, it\'s just 2 pieces of the puzzle. You don\'t see changes because they don\'t give you every piece of the puzzle! How satisfying would it be to get a new puzzle and there\'s a bunch of pieces missing. Yeah, you\'ll make some progress but in the end you\'ll be super frustrated. With those apps though, you don\'t even realize that you\'re missing pieces at all! The reason this app works when so many others don\'t, is because we don\'t bullshit you or sugar coat or leave things out so you don\'t quit. We tell you exactly what you need to do in every area. No missing puzzle pieces. Unlike them where you only get part of the picture, you get the full picture with our app.',
*/    answer: 'Unlike other workout apps, this exact program is <span class="asterius-text-blue-override-graident-text">proven</span> to take you from <span class="asterius-text-blue-override-graident-text">jiggly to shredded</span>, because it isn\'t just a workout app, and it isn\'t just an app for eating. It\'s a full blown life companion app. \
<br /><br />You wake up and get your daily dose of mindset. You\'ll then do a workout that always feels new and exciting. You won\'t count calories, but rather follow a few different methodologies that make it so much easier to <span class="asterius-text-blue-override-graident-text">lose weight and keep it off forever</span>. But more than all that, you also get tasks to improve your lifestyle and the day to day living that\'s causing horrible damage to your mind and body. It\'s an app you\'ll be drawn towards and will benefit you every day for the rest of your life. \
<br /><br />The workout apps out there only give you part of what you need to be doing. You don\'t see lasting changes because <span class="asterius-text-blue-override-graident-text">they don\'t give you every piece of the puzzle</span>! How satisfying would it be to get a new puzzle and there\'s a bunch of pieces missing. Yeah, you\'ll make some progress but in the end you\'ll be super frustrated and quit. With those apps though, you don\'t even realize that you\'re missing pieces! \
<br /><br />With asterius, We tell you exactly what you need to do in every area. No missing puzzle pieces. And real <span class="asterius-text-blue-override-graident-text">change that lasts forever</span>.',
    },

  ];

  return (
    <section className={`${ agreementPlanStyles.agreementSection } container agreement-plan-container`}>
      <h2 className="asterius-text-blue ion-title hidden container-header agreement-plan-header">But what if...</h2>

      <div className="accordion-container hidden">
        {faqs.map( ( faq, index ) => (
          <div key={index} className={agreementPlanStyles.agreementAccordionItem}>
            <div
              className={`${ agreementPlanStyles.agreementQuestion } ${ expandedIndex === index ? agreementPlanStyles.noBottomBorder : ''
                }`}
              onClick={( event ) => toggleAccordion( index, event )}
            >
              {faq.question}
              <span className={agreementPlanStyles.agreementIndicator}>
                {expandedIndex === index ? '-' : '+'}
              </span>
            </div>

            <div
              ref={( el ) => ( contentRefs.current[ index ] = el )}
              className={`container ${ agreementPlanStyles.agreementAnswer } ${ expandedIndex === index ? agreementPlanStyles.expanded : ''
                }`}
            >
              <div className="content" dangerouslySetInnerHTML={{ __html: faq.answer }} />
            </div>
          </div>
        ) )}
      </div>
    </section>
  );
};

const VideoSection: React.FC = () => {
  return (
    <section className="video-section">
      <h2>Our Story in Video</h2>
      <video controls>
        <source src="path-to-your-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </section>
  );
}

interface PriceSectionProps {
  counter: number | null;
  handleDownloadClick: () => void;
}

const PriceSection: React.FC<PriceSectionProps> = ( { counter, handleDownloadClick } ) => {


  const AnimateDissolveIn: React.FC<SectionProps> = ( { children } ) => {
    return (
      <div className="dissolve-in-container">
        <p className="hidden dissolve-in-element">{children}</p>
      </div>
    );
  };

  const AnimateDropIn: React.FC<SectionProps> = ( { children } ) => {
    return (
      <div className="drop-in-container" style={{ display: 'flex', alignItems: 'center' }}>
        <span className="hidden drop-in-element" style={{ display: 'flex', alignItems: 'center' }}>
          {children}
        </span>
      </div>
    );
  };

  const AnimateScaleIn: React.FC<SectionProps> = ( { children } ) => {
    return (
      <div className="scale-in-container">
        <span className="hidden scale-in-element">
          {children}
        </span>
      </div>
    );
  };

  useScrollAnimation( '.price-container', '.price-header', 'dissolve-in-animation' );
  useScrollAnimation( '.dissolve-in-container', '.dissolve-in-element', 'dissolve-in-animation' );
  useScrollAnimation( '.drop-in-container', '.drop-in-element', 'slide-in-down-animation' );
  useScrollAnimation( '.scale-in-container', '.scale-in-element', 'scale-in-animation' );


  /* useScrollAnimation( '.price-container', '.explanatory-line', 'slide-in-down-animation' ); */

  return (
    <section className={`${ pricingStyles.pricingContainer } price-container container`}>

      <h2 className="hidden disolve-in asterius-text-blue ion-title hidden container-header price-header">Get the asterius app</h2>

      {( counter && counter > 0 && counter !== 1 ) && (
        <AnimateDissolveIn>
          <p className={`${ pricingStyles.pricingSave } ion-title-small-text-white`}>
            The <span className="asterius-text-blue-override-graident-text">next {counter} people</span> who <span className="asterius-text-blue-override-graident-text">download</span> the app lock in this <span className="asterius-text-blue-override-graident-text">limited time offer</span> after your free trial ends
          </p>
        </AnimateDissolveIn>
      )}

      {( counter && counter === 1 ) && (
        <AnimateDissolveIn>
          <p className={`${ pricingStyles.pricingSave } ion-title-small-text-white`}>
            The <span className="asterius-text-blue-override-graident-text">next person</span> who <span className="asterius-text-blue-override-graident-text">downloads</span> the app will lock in this <span className="asterius-text-blue-override-graident-text">limited time offer</span> after your free trial ends
          </p>
        </AnimateDissolveIn>
      )}


      {( counter && counter > 0 ) && (
        <div style={{ marginTop: '-15px' }}>
          <AnimateDissolveIn>
            <div className={`${ pricingStyles.usuallyPriceText }`}>Usually</div>
            <div className={`${ pricingStyles.pricingOriginalPrice }`}>$35.99</div>
            <h2 className={`ion-title ${ pricingStyles.pricingPriceNew }`}>$23.99/month</h2>
            <hr className="custom-hr" style={{ width: '125px', marginBottom: '10px', marginTop: '10px' }} />
          </AnimateDissolveIn>
        </div>
      )}

      {( !counter || counter <= 0 ) && (
        <div style={{ marginTop: '-45px' }}>
          <AnimateDissolveIn>
            <h2 className={`ion-title ${ pricingStyles.pricingPriceNew }`}>$35.99/month</h2>
            <hr className="custom-hr" style={{ width: '125px', marginBottom: '10px', marginTop: '10px' }} />
          </AnimateDissolveIn>
        </div>
      )}


      <ul className={pricingStyles.pricingFeatureList}>
        <li className={pricingStyles.pricingFeatureItem}>
          <AnimateDropIn>
            <FontAwesomeIcon icon={faCheckCircle} className={pricingStyles.pricingFeatureIcon} />
            <p className={`ion-title-small-text-white`}>Unlimited access to the asterius app</p>
          </AnimateDropIn>

        </li>
        <li className={pricingStyles.pricingFeatureItem}>
          <AnimateDropIn>
            <FontAwesomeIcon icon={faCheckCircle} className={pricingStyles.pricingFeatureIcon} />
            <p className={`ion-title-small-text-white`}>Try for free for 3 days. No credit card required for the free trial.</p>
          </AnimateDropIn>

        </li>
        <li className={pricingStyles.pricingFeatureItem}>
          <AnimateDropIn>
            <FontAwesomeIcon icon={faCheckCircle} className={pricingStyles.pricingFeatureIcon} />
            <p className={`ion-title-small-text-white`}>Once you decide to continue after the free trial, cancel anytime in your account!</p>
          </AnimateDropIn>

        </li>
      </ul>

      {/*       <p className={`${ pricingStyles.pricingSave } ion-title-small-text-white`}>Download <span className="asterius-text-blue-override-graident-text">asterius</span> now and <span className="asterius-text-blue-override-graident-text">save!</span></p>
 */}



      <AnimateDissolveIn>
        <hr className="custom-hr" style={{ width: '75px', marginBottom: '30px' }} />

        <p className={`${ pricingStyles.pricingSave } ion-title-small-text-white`}>
          <div style={{ marginBottom: '20px' }}>
            So is this the moment you become who <span className="asterius-text-blue-override-graident-text">you were always meant to be</span>?
          </div>

          <div style={{ marginBottom: '20px' }}>
            Or will you stay <span className="asterius-text-blue-override-graident-text">trapped forever</span> in a life and body you're embarrassed of?
          </div>

          <div style={{ marginBottom: '20px' }}>
            You're<span className="asterius-text-blue-override-graident-text"> free to choose.</span>
          </div>

          {/* <div style={{ marginBottom: '20px' }}>
          <span className="asterius-text-blue-override-graident-text">The truth</span> is always simple.
        </div> */}


        </p>

        <button className="sweet-button larger" style={{ marginBottom: '20px' }} onClick={handleDownloadClick}> Get <strong><i>asterius</i></strong><strong></strong> now</button>

      </AnimateDissolveIn>

      {/*       <button className="sweet-button larger hidden scale-in-animation" style={{ marginBottom: '20px' }}> Get <strong><i>asterius</i></strong><strong></strong> now</button>
 */}


    </section>
  );
}

const JunkDrawer: React.FC = () => {
  return (
    <section className="junk-drawer-section">
      <h2>Contact Information</h2>
      <p>Email: contact@yourcompany.com</p>
      <p>Phone: 123-456-7890</p>
      <h2>Employment Opportunities</h2>
      <p>Check out our open positions <a href="/careers">here</a>.</p>
      <h2>Blog</h2>
      <p>Read our latest posts <a href="/blog">here</a>.</p>
    </section>
  );
}

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 xstatikos, LLC. All rights reserved.</p>
    </footer>
  );
}

export default Home;